<template>
 <OModal @shown = "shown()" ref="modalRef">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Data Log</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" >
                    <ODataLookup :dataObject="dsDataLogGroupBy" v-model="dsDataLogGroupBy.current.Created" class="form-control mb-2" >
                        <OColumn field="Created" width="500px" />
                    </ODataLookup>
                    <ODataGrid :dataObject="dsDataLog" hideActionColumn hideSystemColumn 
                        hideMultiselectColumn hideGridMenu disableFilterRow loadDataObject 
                        style="min-height: 300px; height: 400px; max-height: 500px;"
                    >
                        <OColumn v-for="col of distinctColumns" :colId="col" :key="`${uniqueKey}-${col}`">
                            <template #default="{ row }">
                                {{ row.DataJson[col] }}
                            </template>
                        </OColumn>
                        <OColumn field="Status" v-slot="{ row }" width="60px">
                            <span :style="row.Status == 'Success' ? 'color: green;' : 'color: red;'">{{ $t(row.Status) }}</span>
                        </OColumn>
                        <OColumn field="Message" width="500px"  v-slot="{ row }">
                             <span>{{ $t(row.Message) }}</span>
                        </OColumn>
                    </ODataGrid>
                </div>
            </div>
        </div>
    </OModal>
</template>
<script setup>
import { ref, reactive, computed } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
const props = defineProps({
    dataObject: null
  
});

const modalRef = ref(null);
defineExpose({modalRef})

const random =  "_" + crypto.randomUUID();

const dsDataLogGroupBy = getOrCreateDataObject({
    "id": "dsDataLogGroupBy"+random,
    "viewName": "sviw_O365_DataImportLogGroupBy",
    "selectFirstRowOnLoad": true,
    "fields": [
        { "name": "DBObject_ID"},{"name": "GroupKey"},{"name": "SortOrder","sortOrder": 1,"sortDirection": 'desc'},{"name": "Created"}
    ],
    "maxRecords": 100,
    "dynamicLoading": false,
    "whereClause": `[DBObject_ID] = '${props.dataObject.uniqueTable || props.dataObject.viewName}'`
})

const dsDataLog = getOrCreateDataObject({
    "id": "dsDataLog"+random,
    "viewName": "stbv_O365_DataImportLog",
    "selectFirstRowOnLoad": true,
    "fields": [{"name": "PrimKey"},{"name": "ID"}, {"name": "Created","type":"dateTime"},
        {"name": "CreatedBy_ID"}, { "name": "Updated","type":"dateTime"},
        {"name": "UpdatedBy_ID"},{"name": "DBObject_ID"},{"name": "DataRow"},
        {"name": "Status"},{"name": "Message"},{"name": "GroupKey" },{"name": "RowPrimKey"},
    ],
    "masterDataObject_ID": "dsDataLogGroupBy"+random,
    "masterDetailDefinition": [
        {
            "detailField": "GroupKey",
            "operator": "equals",
            "masterField": "GroupKey"
        }
    ]
})
const shown = () =>{
    dsDataLogGroupBy.load();
}


dsDataLog.fields.DataRow.jsonAlias = "DataJson";
const uniqueKey = ref('');
const distinctColumns = computed(() => {
    const arr = [];
    for (const data of dsDataLog.data) {
        for (const jsonData in data.DataJson) {
            if (!arr.includes(jsonData)) {
                arr.push(jsonData); 
            }
        }
    }
    uniqueKey.value = arr.join('');
    return arr;
})


</script>